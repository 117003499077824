.segment {
  margin: 0 0 20px;
  border-bottom: 2px solid #ccc;
  display: flex;
  flex-direction: column;
  position: relative;
  text-align: left;

  ul {
    display: flex;
    padding: 0;
    margin: 4px 0;
  }

  li {
    display: inline-block;
    margin: 0 20px 0 0;
    list-style: none;
  }

  .segment-item {
    &:hover {
      label::before {
        opacity: 1;
        transform: scaleX(1);
      }
    }

    input:checked + label::before {
      opacity: 1;
      transform: scaleX(1);
    }

    label {
      cursor: pointer;
      position: relative;
      padding: 5px 13px;
      text-decoration: none;
      text-transform: uppercase;
      font-size: 13px;

      &::before {
        content: '';
        position: absolute;
        width: 100%;
        bottom: -2px;
        left: 0;
        border-bottom: 2px solid grey;
        opacity: 0;
        transform: scaleX(0);
        transition: all 0.25s ease-in-out;
      }
    }

    input {
      flex: 1;
      border-style: none;
      outline: none;
      color: #313b3f;
      display: none;
    }
  }
}
