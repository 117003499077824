.payment-form {
  padding: 20px 30px 30px;
  border: 1px solid #ccc;
  margin: 0px auto 40px;

  max-width: 550px;

  .generate-data {
    cursor: pointer;
    font-weight: bold;
    text-decoration-line: underline;
  }
}