.country {
  display: flex;
  align-items: center;
  flex: 1;
  background: transparent;
  font-weight: 400;
  color: #31325f;
  outline: none;
  cursor: text;

  &::before {
    display: inline-flex;
    content: '';
    width: 21px;
    height: 15px;
    background-position: -1000px -1000px;
    background-image: url(./flags.svg);
    background-repeat: no-repeat;
    margin-right: 10px;
    margin-bottom: 20px;
  }

  .form-select label {
    top: -14px;
    left: -30px;
  }
}

// 8 rows x 17 columns
$flags: (
  'ad': -10px -10px,
  'ae': -41px -10px,
  'ag': -72px -10px,
  'am': -103px -10px,
  'ar': -134px -10px,
  'at': -165px -10px,
  'au': -196px -10px,
  'be': -227px -10px,
  'bf': -258px -10px,
  'bg': -289px -10px,
  'bo': -320px -10px,
  'br': -351px -10px,
  'ca': -382px -10px,
  'cd': -413px -10px,
  'cg': -444px -10px,
  'ch': -475px -10px,
  'cl': -506px -10px,
  'cm': -10px -35px,
  'cn': -41px -35px,
  'co': -72px -35px,
  'cr': -103px -35px,
  'cz': -134px -35px,
  'de': -165px -35px,
  'dj': -196px -35px,
  'dk': -227px -35px,
  'dz': -258px -35px,
  'ec': -289px -35px,
  'ee': -320px -35px,
  'eg': -351px -35px,
  'es': -382px -35px,
  'eu': -413px -35px,
  'fi': -444px -35px,
  'fo': -475px -35px,
  'fr': -506px -35px,
  'ga': -10px -60px,
  'gb': -41px -60px,
  'gl': -72px -60px,
  'gm': -103px -60px,
  'gr': -134px -60px,
  'gt': -165px -60px,
  'gu': -196px -60px,
  'hk': -227px -60px,
  'hn': -258px -60px,
  'ht': -289px -60px,
  'hu': -320px -60px,
  'id': -351px -60px,
  'ie': -382px -60px,
  'il': -413px -60px,
  'im': -444px -60px,
  'in': -475px -60px,
  'iq': -506px -60px,
  'ir': -10px -85px,
  'is': -41px -85px,
  'it': -72px -85px,
  'je': -103px -85px,
  'jm': -134px -85px,
  'jo': -165px -85px,
  'jp': -196px -85px,
  'kg': -227px -85px,
  'kn': -258px -85px,
  'kp': -289px -85px,
  'kr': -320px -85px,
  'kw': -351px -85px,
  'kz': -382px -85px,
  'la': -413px -85px,
  'lb': -444px -85px,
  'lc': -475px -85px,
  'ls': -506px -85px,
  'lt': -10px -110px,
  'lu': -41px -110px,
  'lv': -72px -110px,
  'ma': -103px -110px,
  'mg': -134px -110px,
  'mk': -165px -110px,
  'ml': -196px -110px,
  'mm': -227px -110px,
  'mt': -258px -110px,
  'mx': -289px -110px,
  'my': -320px -110px,
  'na': -351px -110px,
  'ne': -382px -110px,
  'ng': -413px -110px,
  'ni': -444px -110px,
  'nl': -475px -110px,
  'no': -506px -110px,
  'nz': -10px -135px,
  'om': -41px -135px,
  'pa': -72px -135px,
  'pe': -103px -135px,
  'pg': -134px -135px,
  'ph': -165px -135px,
  'pk': -196px -135px,
  'pl': -227px -135px,
  'pr': -258px -135px,
  'ps': -289px -135px,
  'pt': -320px -135px,
  'py': -351px -135px,
  'qa': -382px -135px,
  'ro': -444px -135px,
  'ru': -475px -135px,
  'rw': -506px -135px,
  'sa': -10px -160px,
  'se': -41px -160px,
  'sg': -72px -160px,
  'si': -103px -160px,
  'sk': -134px -160px,
  'sl': -165px -160px,
  'sn': -196px -160px,
  'so': -227px -160px,
  'sv': -258px -160px,
  'td': -289px -160px,
  'th': -320px -160px,
  'tj': -351px -160px,
  'tl': -382px -160px,
  'tr': -413px -160px,
  'tw': -444px -160px,
  'tz': -475px -160px,
  'ua': -506px -160px,
  'us': -10px -185px,
  'uy': -41px -185px,
  've': -72px -185px,
  'vn': -103px -185px,
  'xx': -134px -185px,
  'ye': -165px -185px,
  'za': -196px -185px,
);

// map spritemap to single flag
@each $flag, $background-position in $flags {
  .country--#{$flag}::before {
    background-position: $background-position;
  }
}
