.order-summary {
  padding: 0 2rem 2rem;

  .checkout-orders {
    height: 300px;
    overflow-y: auto;
    border-bottom: 1px solid #ccc;

    @media screen and (max-width: 800px) {
      height: 200px;
    }
  }

  .demo-info {
    background-color: #ccc;
    padding: 0.75rem;

    h5 {
      margin: 0;
    }
  }

  .cart-item img {
    width: 20%;
  }

  .price-info {
    display: flex;
    margin: 15px 0;
    &--large {
      font-size: 24px;
    }
    &__price {
      font-weight: bold;
      margin-left: auto;
    }
  }
}
