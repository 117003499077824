.StripeElement {
  width: 100%;
  padding: 10px 10px 10px 5px;
  cursor: pointer;
  background: none;
  background-color: white;
  font-size: 18px;
  display: block;
  color: #313b3f;
  border: none;
  border-radius: 0;
  border-bottom: 1px solid grey;
  margin-bottom: 25px;
  -webkit-appearance: none;
}

.validation-error {
  color: #b03c27;
}
