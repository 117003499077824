.collections-overview {
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 800px) {
    button {
      margin: 0 auto;
    }
  }
}
