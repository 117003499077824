.collection-list {
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;

  .title {
    font-size: 28px;
    margin-bottom: 25px;
  }

  &-items {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 10px;
    
    @media screen and (max-width: 800px) {
      grid-template-columns: 1fr 1fr;
      grid-gap: 15px;
    }

    @media screen and (max-width: 500px) {
      grid-template-columns: 1fr !important;
      gap: 15px;
    }

  }
  
  @media screen and (max-width: 800px) {
   align-items: center;
  }
}
