.meter {
  background: #dadada;
  display: block;
  width: 100%;
  height: 1.2rem;
}

.meter-bar {
  --weak: #dc3545;
  --moderate: #ffc107;
  --strong: #17a2b8;
  --perfect: #28a745;
  height: 1.2rem;
  color: white;
  line-height: 1px;
  font-weight: bold;
  display: block;
  display: flex;
  justify-content: center;
  align-items: center;

  &[data-value='1'] {
    background: var(--weak);
  }
  &[data-value='2'] {
    background: var(--moderate);
  }
  &[data-value='3'] {
    background: var(--strong);
  }
  &[data-value='4'] {
    background: var(--perfect);
  }
}
