.contact-form {
  width: 100%;
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 800px) {
    width: 100%;
    button {
      margin: 0 auto;
    }
  }

  .title {
    margin: 10px 0;
  }

  .contact-message {
    display: flex;
    flex-direction: column;
    
    * {
      margin-bottom: 1rem;
    }

    textarea {
      min-height: 100px;
      border: 1px solid grey;
      color: grey;
      font-size: 18px;
    }
  }
}
