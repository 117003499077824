.form-select {
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;

  label {
    top: -14px;
    left: -30px;
    color: black;
    font-size: 16px;
    font-weight: normal;
    position: absolute;
    pointer-events: none;
  }

  select {
    cursor: pointer;
    background: none;
    background-color: white;
    font-size: 18px;
    padding: 10px 10px 10px 5px;
    display: block;
    width: 100%;
    color: #313b3f;
    border: none;
    border-radius: 0;
    border-bottom: 1px solid grey;    
    margin-bottom: 25px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }
}