.sign-up {
  width: 380px;
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 800px) {
    width: 100%;
    button {
      margin: 0 auto;
    }
  }

  .title {
    margin: 10px 0;
  }
}
