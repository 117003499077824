.sign-in {
  width: 380px;
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 800px) {
    width: 100%;

    .buttons {
      flex-direction: column;

      button {
        margin-bottom: 0.5rem;
      }
    }
  }

  .title {
    margin: 10px 0;
  }

  .buttons {
    display: flex;
    justify-content: space-between;
  }
}
